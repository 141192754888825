/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 19px;

$baseLineHeight: 29px;

$h1Size: 40px;

$h2Size: 35px;

$h3Size: 30px;

$h4Size: 25px;

$h5Size: 20px;

$h6Size: 20px;

$mainFont: Barlow, sans-serif;

$displayFont: Barlow, sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #de6c15;
$secondary: #2193ea;
$light: #fff;
$medium: #f4f4f4;
$dark: #2e2e2e;
$border: #999999;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1584px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	plus: "+", 
	exclamation: "!", 
	consulting: ""\e900"", 
	magnifying-glass: ""\e901"", 
	pen: ""\e902"", 
	angle-up: ""\f077"", 
	angle-down: ""\f078"", 
	angle-left: ""\f053"", 
	angle-right: ""\f054"", 
);

$bgIcons: (
	blackStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJTdGVybl9taXRfNV9aYWNrZW5fMSIgZGF0YS1uYW1lPSJTdGVybiBtaXQgNSBaYWNrZW4gMSIgY2xhc3M9ImNscy0xIiBkPSJNMzItLjA0NEw0Mi41NDYsMjAuMDgyLDY0LDI0LjQxOCw0OS4wNjQsNDEuMTkzLDUxLjc3NSw2NCwzMiw1NC4yMzksMTIuMjI1LDY0bDIuNzEtMjIuODA2TDAsMjQuNDE4bDIxLjQ0OS00LjMzNkwzMi0uMDQ0Ii8+Cjwvc3ZnPgo=", 
	blackTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJSZWNodGVja18xIiBkYXRhLW5hbWU9IlJlY2h0ZWNrIDEiIGNsYXNzPSJjbHMtMSIgZD0iTTUxLjc1MSw2LjVMNTcuNSwxMi4yNDksMTIuMjQ5LDU3LjUsNi41LDUxLjc1MSw1MS43NTEsNi41Ii8+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNNTcuNSw1MS43NTFMNTEuNzUxLDU3LjUsNi41LDEyLjI0OSwxMi4yNDksNi41LDU3LjUsNTEuNzUxIi8+Cjwvc3ZnPgo=", 
	blackCheck: "data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU2IDUxNS41NTYiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTE1LjU1NiA1MTUuNTU2IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDI3NC4yMjYgMTc2LjU0OSAxNzYuODg2IDMzOS4wMDctMzM4LjY3Mi00OC42Ny00Ny45OTctMjkwLjMzNyAyOTAtMTI4LjU1My0xMjguNTUyeiIvPjwvc3ZnPg==", 
	whiteStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlN0ZXJuX21pdF81X1phY2tlbl8xIiBkYXRhLW5hbWU9IlN0ZXJuIG1pdCA1IFphY2tlbiAxIiBjbGFzcz0iY2xzLTEiIGQ9Ik0zMi0uMDQ0TDQyLjU0NiwyMC4wODIsNjQsMjQuNDE4LDQ5LjA2NCw0MS4xOTMsNTEuNzc1LDY0LDMyLDU0LjIzOSwxMi4yMjUsNjRsMi43MS0yMi44MDZMMCwyNC40MThsMjEuNDQ5LTQuMzM2TDMyLS4wNDQiLz4KPC9zdmc+Cg==", 
	whiteTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzEiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSIgY2xhc3M9ImNscy0xIiBkPSJNNTEuNzUxLDYuNUw1Ny41LDEyLjI0OSwxMi4yNDksNTcuNSw2LjUsNTEuNzUxLDUxLjc1MSw2LjUiLz4KICA8cGF0aCBpZD0iUmVjaHRlY2tfMV9Lb3BpZSIgZGF0YS1uYW1lPSJSZWNodGVjayAxIEtvcGllIiBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny41LDUxLjc1MUw1MS43NTEsNTcuNSw2LjUsMTIuMjQ5LDEyLjI0OSw2LjUsNTcuNSw1MS43NTEiLz4KPC9zdmc+Cg==", 
	whiteCheck: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNMjIuNiw1NS4zMkw2NC4zMiwxMy42LDU4LjY2Myw3Ljk0NCwyMi42LDQ0LjAwNiw2LjMzNywyNy43NDMsMC42OCwzMy40WiIvPgo8L3N2Zz4K", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (8),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (4, 6, 7),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (6, 10),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (3, 4, 5, 6, 7, 8, 9),
		prefix: (5),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (4, 5, 6, 7, 8, 12),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (5, 6, 7),
		suffix: (1),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (3, 4, 6, 8, 9),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

