// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $dark;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $primary; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	min-width:70%;

	.row {
		.branding,
		.phone {
			display:none;
		}

		> div {
			width:100%;
		}
	}

	nav {
		width:100%;

		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {

						.subToggler {
							&:after {
								content:'\f054';
								font-family:$iconFont;
								font-size:rem(14px);
							}
						}

						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						&:before {
							content:'\f054';
							font-family:$iconFont;
							font-size:rem(14px);
						}
					}

					&:last-child {
						margin-bottom:rem(60px);
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background:$light;
		font-size:rem(16px);
		transition:background 400ms;
		
		.row {
			display:flex;
			align-items:center;

			.branding,
			.phone {
				display:block;
				white-space:nowrap;
			}
		}

		.phone {
			color:$dark;
			transition:color 400ms;
		}
	
		.branding {
			padding:rem($baseGap) rem($baseGap * 3);
			width:calc(41.66667% - #{rem($baseGap * 2)});
			background:$primary;
			margin:0 rem($baseGap);

			img {
				width:rem(280px);
				height:auto;
			}

			+ div {
				padding:0;
				width:58.33334%;
				margin-left:rem(-$baseGap);
				display:flex;
				flex-wrap:nowrap;
			}
		}

		nav {
			margin:0 rem($baseGap);
			padding-right:rem($baseGap);
			border-right:1px solid $dark;
			transition:all 400ms;

			ul {
				&.navi {
					li {
						> a {
							padding:0;
							background:transparent;
							color:$dark;
							transition:color 400ms;

							&:hover,
							&.active {
								color:$primary;
								background:transparent;
							}

							&.active {
								font-weight:700;
							}
						} 

						&:hover,
						&.active {
							> a {
								color:$primary;
								background:transparent;
							}
						}
						
						&.hasSub {
							.subToggler {
								&:after {
									font-size:rem(12px);
								}
							}

							> span {
								padding:0;
								background:transparent;
								color:$dark;
								transition:color 400ms;	

								&:hover,
								&.active {
									color:$primary;
									background:transparent;
								}

								&.active {
									font-weight:700;
								}
							}

							&:hover,
							&.active {
								> span {
									color:$primary;
									background:transparent;
								}
							}

							.sub {
								padding-top:rem($baseGap / 2);
								padding-bottom:rem($baseGap / 2);

								li {
									padding:rem($baseGap / 2) rem($baseGap);
								}
							}
						}
						&.navBack {
							&:before {
								transform:none;
								text-align:left;
							}

							color:$dark;

							&:hover {
								color:$primary;
								background:transparent;
							}
						}

						&:last-child {
							margin-bottom:0;
						}
					}
				}
			}
		}
		.scrollingDown & {
			background:$primary;
			
			.phone {
				color:$light;
				transition:color 400ms;
			}

			nav {

				border-color:$light;

				ul {
					&.navi {
						li {
							a {
								color:$light;

								&:hover,
								&.active {
									color:$light;
									opacity:.5;
								}
							} 

							&:hover,
							&.active {
								> a {
									color:$light;
									opacity:.5;
								}
							}
							
							&.hasSub {
								> span {
									color:$light;

									&:hover,
									&.active {
										color:$light;
										opacity:.5;
									}
								}	

								&:hover,
								&.active {
									> span {
										color:$light;
										opacity:.5;
									}
								}

								.sub {
									background:$primary;

									li {
										a {
											color:$light;
										}
									}
								}
							}

							&.navBack {
								color:$light;
								&:hover {
									color:$light;
									opacity:.5;
								}
							}
						}
					}
				}
			}
		}

		@include breakpoint(giant) {
			nav {
				margin:0 rem($baseGap * 2);
				padding-right:rem($baseGap * 2);
			}
		}

		@include breakpoint(huge) {
			font-size:rem($baseFontSize);
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	max-width:100vw;

	.branding {
		img {
			height:rem(36px);
			width:auto;
		}
	}


	@include breakpoint($break) {
		display: none;
	}
}