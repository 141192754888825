#pageWrap {
	overflow:hidden;
}
// ROW STYLES
//////////////////////////////
main, #footer {
	.row {
		&.full {
			display:block;
			padding-top:rem($baseGap * 2);
			padding-bottom:rem($baseGap * 2);

			&.noPaddingTop {
				padding-top:0;
			}

			&.noPaddingBottom {
				padding-bottom:0;
			}

			.row {
				max-width:rem($rowMaxWidth);
				margin-left:auto;
				margin-right:auto;
				padding-top:0;
				padding-bottom:0;

				+ .row {
					padding-top:rem($baseGap * 2);
				}
			}

			.col {
				.row {
					width:calc(100% + #{rem($baseGap * 2)});
					margin-left:rem(-$baseGap);
				}
			}

			form {
				.row {
					width:100%;
					margin-left:auto;
					margin-right:auto;

					+ .row {
						padding-top:0;
					}
				}
			}
		}

		@include breakpoint(small) {
			&.full {
				padding-top:rem($baseGap * 4);
				padding-bottom:rem($baseGap * 4);

				.row {
					+ .row {
						padding-top:rem($baseGap * 2);
					}
				}
			}
		}

		@include breakpoint(large) {
			&.full {
				padding-top:rem($baseGap * 5);
				padding-bottom:rem($baseGap * 5);

				.row {
					+ .row {
						padding-top:rem($baseGap * 2.5);
					}
				}
			}
		}

		@include breakpoint(huge) {
			&.full {
				padding-top:rem($baseGap * 6);
				padding-bottom:rem($baseGap * 6);

				.row {
					+ .row {
						padding-top:rem($baseGap * 3);
					}
				}
			}
		}

		@include breakpoint(full) {
			&.full {
				padding-top:rem($baseGap * 7);
				padding-bottom:rem($baseGap * 7);

				.row {
					+ .row {
						padding-top:rem($baseGap * 3.5);
					}
				}
			}
		}
	}
}
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-top:rem($barHeight);
	background:image-url('index/bgClaim.jpg') bottom center no-repeat $primary;
	background-size:contain;

	blockquote {
		padding:rem($baseGap * 2) 0;

		&.bgOrange {
			background:transparent;
		}

		p {
			&.headline {
				@extend .h1;

				span {
					display:block;
					font-size:rem($baseFontSize);
				}
			}

			&.subHeadline {
				margin-bottom:rem($baseLineHeight);
			}
		}
	}

	.iconListContainer {
		ul {
			&.iconList {
				display:flex;
				align-items:center;
				justify-content:space-between;
				width:calc(100% + #{rem($baseGap * 2)});
				margin:0 rem(-$baseGap);

				li {
					width:calc(100% / 3);
					display:flex;
					align-items:center;
					justify-content:center;
					padding:rem($baseGap);
					background:$light;
					flex-wrap:wrap;

					&:after {
						font-family:$iconFont;
						font-size:rem(50px);
						line-height:1.5em;
						width:100%;
						text-align:center;
					}

					&:nth-child(1) {
						&:after {
							content:'\e900';
						}
					}
					&:nth-child(2) {
						&:after {
							content:'\e902';
						}
					}
					&:nth-child(3) {
						&:after {
							content:'\e901';
						}
					}
				}
			}
		}
	}

	// @include breakpoint(tiny) {
	// 	background:image-url('layout/tiny-bgHeader.jpg') right bottom no-repeat;
	// 	background-size:cover;

	// 	blockquote {
	// 		padding:rem($baseGap * 2) rem($baseGap);
	// 		margin-left:rem(-$baseGap);

	// 		&.bgOrange {
	// 			background:image-url('index/bgClaim.jpg') bottom center no-repeat $primary;
	// 			background-size:contain;
	// 		}
	// 	}
	// }

	@include breakpoint(small) {
		background:image-url('layout/tiny-bgHeader.jpg') right bottom no-repeat;
		background-size:cover;

		&:before {
			width:100%;
			height:100%;
			position:absolute;
			top:0;
			left:0;
			background:rgba(0,0,0,.60);
			content:'';
			z-index:0;
		}

		blockquote {
			padding:rem($baseGap * 4) rem($baseGap);
			background:transparent;
			position:relative;
			z-index:2;
		}
	}

	@include breakpoint(large) {
		background:image-url('layout/large-bgHeader.jpg') center top no-repeat;
		background-size:cover;
		margin-top:rem(81px);
		
		// &:before {
		// 	width:100vw;
		// 	height:rem(140px);
		// 	background:$light;
		// 	content:'';
		// 	position:absolute;
		// 	bottom:0;
		// 	left:50%;
		// 	transform:translateX(-50%);
		// }

		blockquote {
			padding:rem($baseGap * 5) rem($baseGap);
			margin-left:0;
			position:relative;
			z-index:2;
			background:transparent;

			p {
				&.headline {
					font-size:rem(50px);
					line-height:1.2em;
					margin-bottom:rem($baseGap);
	
					span {
						display:block;
						font-size:rem($baseFontSize);
					}
				}
	
				&.subHeadline {
					margin-bottom:rem($baseLineHeight);
				}
			}

		}

		.iconListContainer {
			background:$light;
			position:relative;
			display:none;
	
			// ul {
			// 	&.iconList {
			// 		margin-left:rem(-$baseGap * 2);
			// 		padding-left:rem($baseGap);
			// 		background:$light;
			// 	}
			// }
		}
	}

	@include breakpoint(giant) {
		margin-top:rem(83px);
		background:image-url('layout/giant-bgHeader.jpg') center top no-repeat;
		background-size:cover;

		blockquote {
			p {
				font-size:rem(20px);

				&.headline {
					font-size:rem(60px);

					span {
						display:block;
						font-size:rem(20px);
					}
				}

				&.subHeadline {
					margin-bottom:rem($baseLineHeight);
				}
			}
		}
	}

	@include breakpoint(huge) {

		// &:before {
		// 	display:none;
		// }

		// blockquote {
		// 	padding:rem($baseGap * 6) rem($baseGap * 3) 0;
		// }

		// .iconListContainer {
		// 	background:transparent;
		// 	position:relative;
		// 	text-align:right;
	
		// 	ul {
		// 		&.iconList {
		// 			margin-left:rem(-$baseGap * 2);
		// 			padding-left:rem($baseGap);
		// 			background:transparent;
		// 			display:flex;
		// 			align-items:flex-end;
		// 			justify-content:flex-end;

		// 			li {
		// 				width:rem(200px);

		// 				&:not(:first-child) {
		// 					margin-left:rem($baseGap * 2);
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}

	@include breakpoint(full) {
		blockquote {
			padding:rem($baseGap * 7) rem($baseGap);

			// &:before {
			// 	height:calc(100% + #{rem(198px)});
			// 	bottom:rem(-198px);
			// }

			p {
				&.headline {
					font-size:rem(70px);
					line-height:1.1em,
				}
			}
		}

		.iconListContainer {
			ul {
				&.iconList {
					li {
						width:rem(234px);
						padding-top:rem($baseGap * 2);
						padding-bottom:rem($baseGap * 2);

						&:after {
							font-size:rem(65px);
						}
					}
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,	ol,	ul,	dl,	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;

		&.btnList {
			li {
				background:transparent;
				margin:0 0 rem($baseGap) 0;
				padding:0;
				width:100%;

				&:last-child {
					margin:0;
				}

				&:before {
					display:none;
				}

				a {
					@extend .btn;
					width:100%;
				}
			}
		}

		&.ctaList {
			li {
				margin:0;
				padding:0;

				&:not(:last-child) {
					margin-bottom:rem($baseGap);
				}

				&:before {
					display:none;
				}
			}
		}
	}

	img {
		margin-bottom:rem($baseGap * 2);

		&.noMarginBottom {
			margin-bottom:0;
		}
	}

	.contentSliderContainer {
		position:relative;

		&:before {
			width:100%;
			height:calc(100% - #{rem($baseGap * 5)});
			content:'';
			background:$primary;
			position:absolute;
			top:0;
			left:0;
		}

		.contentSlider {
			height:100%;
			padding-bottom:rem($baseGap);

			img {
				margin-bottom:0;
			}

			.sliderText {
				padding:rem($baseGap);
				font-size:rem(16px);
				line-height:1.4em;
				background:$light;

				.btn {
					font-size:rem(16px);
				}
			}

			.swiper-slide {
				box-shadow:rem(3px) rem(3px) 0 rgba(0,0,0,0.25);
				height:100%;
				background:$light;
			}

			.swiper-button-prev,
			.swiper-button-next {
				display:block;
				width:rem(40px);
				height:rem(40px);
				z-index:11;
				background:$light;

				display:none;

				@include breakpoint(small) {
					display:block;
				}

				&:before {
					width:100%;
					height:100%;
					display:flex;
					align-items:center;
					justify-content:center;
					content:'X';
					color:$dark;
					font-family:$iconFont;
				}
			}

			.swiper-button-prev {
				left:0;

				&:before {
					content:'\f053';
				}
			}

			.swiper-button-next {
				right:0;

				&:before {
					content:'\f054';
				}
			}
		}
	}

	.teaserArchIng,
	.teaserSV {
		display:block;

		// &:before {
		// 	width:rem(170px);
		// 	height:rem(102px);
		// 	content:'';
		// 	margin-right:1rem;
		// 	display:block;
		// 	margin-bottom:.5rem;
		// }
	}

	.teaserArchIng {
		margin-bottom:rem($baseLineHeight * 2);

		// &:before {
		// 	background:image-url('index/arch-ing.jpg') 0 0 no-repeat;
		// 	background-size:contain;
		// }

		// &:after {
		// 	background:image-url('layout/bundesadler.png') 0 0 no-repeat;
		// 	background-size:contain;
		// 	width:rem(102px);
		// 	height:rem(102px);
		// 	content:'';
		// 	margin-top:rem(10px);
		// 	display:inline-block;
		// }
	}

	.teaserSV {
		// &:before {
		// 	background:image-url('index/gerichtssachverstaendige.jpg') 0 0 no-repeat;
		// 	background-size:contain;
		// }
	}

	@include breakpoint(tiny) {
		.contentSliderContainer {
			&:before {
				width:100%;
				height:calc(100% - #{rem($baseGap * 5)});
				content:'';
				background:$primary;
				position:absolute;
				top:0;
				left:0;
			}
		}

		.teaserArchIng,
		.teaserSV {
			display:flex;
			align-items:flex-start;
		}

		.teaserArchIng {
			// &:before {
			// 	width:rem(170px);
			// 	height:rem(65px);
			// }
			// 	&:after {
			// 	width:rem(104px);
			// 	height:rem(65px);
			// 	content:'';
			// 	margin-top:0;
			// 	display:inline-block;
			// 	background-position:top left;
			// }
		}

		.teaserSV {
			// &:before {
			// 	width:rem(102px);
			// 	height:rem(65px);
			// }		
		}
	}

	@include breakpoint(small) {

		ul:not(.unstyledList) {
			&.ctaList {
				li {
					display:inline-block;

					&:not(:last-child) {
						margin-bottom:0;
						margin-right:rem($baseGap);
					}
				}
			}

			&.colCountList {
				column-count:2;
				column-gap:rem($baseGap * 2);
			}
		}

		.contentSliderContainer {
			&:before {
				width:100%;
				height:calc(100% - #{rem($baseGap * 9)});
				content:'';
				background:$primary;
				position:absolute;
				top:0;
				left:0;
			}

			.contentSlider {
				.sliderText {
					padding:rem($baseGap * 2);
					font-size:rem($baseFontSize);
				}
			}
		}

		.teaserArchIng,
		.teaserSV {
			font-size:rem($h4Size);
			font-weight:300;
			position:relative;
		}

		.teaserArchIng {
			// &:after {
			// 	width:rem(120px);
			// 	height:rem(65px);
			// 	content:'';
			// 	margin-top:0;
			// 	display:inline-block;
			// 	background-position:top left;
			// 	position:absolute;
			// 	top:0;
			// 	left:rem(530px);
			// }
		}
	}

	@include breakpoint(medium) {
		.teaserArchIng {
			width:rem(600px);
			// &:after {
			// 	width:rem(120px);
			// 	height:rem(70px);
			// 	content:'';
			// 	margin-top:0;
			// 	display:inline-block;
			// 	background-position:top left;
			// 	position:absolute;
			// 	top:0;
			// 	left:rem(530px);
			// }
		}
	}

	@include breakpoint(large) {
		ul:not(.unstyledList) {
			&.btnList {
				display:flex;
				flex-wrap:wrap;

				li {
					margin:0 rem($baseGap) rem($baseGap) 0;
					width:auto;

					&:last-child {
						margin:0;
					}
				}
			}

			&.ctaList {
				li {
					display:block;

					&:not(:last-child) {
						margin-bottom:rem($baseGap);
						margin-right:0;
					}
				}
			}
		}

		.contentSliderContainer {
			&:before {
				width:100%;
				height:calc(100% - #{rem($baseGap * 11)});
				background:$primary;
				background-size:cover;
			}
		}

		.teaserArchIng,
		.teaserSV {
			display:flex;
			align-items:flex-start;
			font-size:rem($h5Size);
			font-weight:300;
			line-height:1.2em;
			width:auto;
		}

		.teaserArchIng {
			margin-bottom:0;

			// &:before {
			// 	width:rem(140px);
			// 	height:rem(60px);
			// 	margin-bottom:0;
			// }

			// &:after {
			// 	width:rem(60px);
			// 	left:rem(420px);
			// 	top:rem(-5px);
			// }
		}

		.teaserSV {
			// &:before {
			// 	width:rem(110px);
			// 	height:rem(60px);
			// 	margin-bottom:0;
			// }		
		}
	}

	@include breakpoint(giant) {
		.teaserArchIng {
			margin-bottom:0;
			width:rem(500px);

			// &:before {
			// 	width:rem(140px);
			// 	height:rem(60px);
			// 	margin-bottom:0;
			// }

			// &:after {
			// 	width:rem(60px);
			// 	left:rem(420px);
			// 	top:rem(-5px);
			// }
		}
	}

	@include breakpoint(huge) {
		ul:not(.unstyledList) {
			&.ctaList {
				li {
					display:inline-block;

					&:not(:last-child) {
						margin-bottom:0;
						margin-right:rem($baseGap * 2);
					}
				}
			}

			&.colCountList {
				column-count:4;
				column-gap:rem($baseGap);
			}
		}

		.contentSliderContainer {
			&:before {
				width:100%;
				height:calc(100% - #{rem($baseGap * 13)});
			}
		}
	}

	@include breakpoint(full) {
		.contentSliderContainer {
			&:before {
				width:100%;
				height:calc(100% - #{rem($baseGap * 15)});
			}
		}

		.row {
			&.hugeCenter {
				.col {
					min-height:100%;
				}

				+ .hugeCenter {
					padding-top:0!important;
				}
			}
		}

		.textContainer {
			padding-top:rem($baseGap * 7);
			padding-bottom:rem($baseGap * 7);
		}

		.imageContainer {
			position:relative;
			width:100%;
			min-height:100%;
			overflow:hidden;

			picture {
				position:absolute;
				width:100%;
				height:100%;

				img {
					position:absolute;
					top:0;
					left:50%;
					height:auto;
					width:auto;
					max-width:none;
					max-height:auto;
					min-width:100%;
					min-height:100%;
					transform:translateX(-50%);
				}
			}
		}

		.teaserArchIng,
		.teaserSV {
			align-items:center;
			font-size:rem($h4Size);
		}

		.teaserArchIng {
			width:rem(650px);

			// &:before {
			// 	width:10rem;
			// 	height:rem(80px);
			// }

			// &:after {
			// 	width:rem(150px);
			// 	height:rem(90px);
			// 	left:rem(540px);
			// 	top:rem(-5px);
			// }
		}

		.teaserSV {
			// &:before {
			// 	width:4.5rem;
			// 	height:rem(80px);
			// }		
		}
	}
}

#privacyPolicy {
	a {
		display: inline-block; 
		word-break: break-all;
	}
}


.zertifikateContainer {
	justify-content: space-between;
	gap: 1rem 0;
	@include breakpoint (large) {
		gap: unset;
	}
}
.zertifikate {

	@include breakpoint (small) {
		display: flex;
		align-items: center;	
		justify-content: space-between;
	}



	:not(span) {
		align-items: last baseline;
	}

	img {
		object-fit: cover;
		overflow: visible;
		margin-bottom: 0;
		&.arch-ing {
			width: 6rem;
			height: 3rem;
			margin: 0 1rem 1rem 0;

			@include breakpoint (small ) {
				margin: 0 1rem 0 0;
			}
			@include breakpoint (large) {
				width: 9rem;
				height: 4rem;
				margin: 0 2rem 0 0;
			}
		}

		&.bundesadler {
			width: 4rem;


			@include breakpoint (small) {
				margin-left: 1rem;
			}

			@include breakpoint (large) {
				width: 7rem;
				margin-left: 2rem;
			}
		}

		&.gerichtssachverständige {
			width: 4rem;
			margin-right: 2rem;
			margin-top: 2rem;
			margin-bottom: 1rem;
			margin-right: 1rem;


			@include breakpoint (small) {
				margin: 0 2rem 0 0;
			}
		}
	}

	.teaserArchIng {
		word-break: break-word;
		hyphens: auto;
		margin-bottom: 1rem;

		@include breakpoint (small) {
			margin-bottom: 0;
		}
	}
}

// FOOTER
//////////////////////////////

#footer {
	margin-bottom:rem(30px);

	.googleMaps {
		&:not(.videoTag) {
			height:rem(320px);
			padding:0;
		}
	}

	address,
	dl {
		margin-bottom:rem($baseLineHeight);
	}

	dl {
		&.businessHours {
			span {
				display:block;
			}
		}
	}

	ul {
		&.naviAdd {
			margin-top:rem($baseLineHeight);

			li {
				&:not(:last-child) {
					margin-bottom:rem(1px);
				}

				a {
					color:$light;
					text-decoration:none;
					display:block;
					background:$primary;
					padding:rem($baseGap);
					text-align:center;

					&:hover,
					&.active {
						background:$dark;
						color:$light;
					}
				}
			}
		}
	}

	@include breakpoint(tiny) {
		dl {
			dt, 
			dd {
				display:inline-block;
				vertical-align:top;
			}

			dt {
				width:rem(90px);
			}

			dd {
				width:calc(100% - #{rem(90px)});
			}

			&.businessHours {
				dt {
					width:rem(180px);
				}

				dd {
					width:calc(100% - #{rem(180px)});
				}
			}
		}
	}

	@include breakpoint(medium) {
		dl {
			&.businessHours {
				span {
					display:inline;

					&:before {
						display:inline-block;
						content:'+';
						margin:0 rem($baseGap / 2);
					}
				}
			}
		}
	}

	@include breakpoint(large) {
		ul {
			&.naviAdd {
				display:flex;

				li {
					&:not(:last-child) {
						margin-bottom:0;
						margin-right:rem($baseGap * 2);
					}
	
					a {
						color:$dark;
						text-decoration:none;
						display:inline;
						background:transparent;
						padding:0;
						text-align:left;
	
						&:hover,
						&.active {
							background:transparent;
							color:$primary;
						}
					}
				}
			}
		}

		.googleMaps {
			&:not(.videoTag) {
				height:rem(400px);
				padding:0;
			}
		}
	}

	@include breakpoint(giant) {
		dl {
			&.businessHours {
				dt, dd {
					display:block;
					width:100%;
				}
			}
		}
	}

	@include breakpoint(huge) {
		.googleMaps {
			&:not(.videoTag) {
				height:rem(560px);
				padding:0;
			}
		}

		ul {
			&.naviAdd {
				display:block;
				margin-top:0;

				li {
					&:not(:last-child) {
						margin-bottom:0;
						margin-right:rem($baseGap * 2);
					}
	
					a {
						color:$dark;
						text-decoration:none;
						display:inline;
						background:transparent;
						padding:0;
						text-align:left;
	
						&:hover,
						&.active {
							background:transparent;
							color:$primary;
						}
					}
				}
			}
		}
	}
}

body {
	&:not(.index) {
		main {
			padding-top:rem($barHeight);

			@include breakpoint(large) {
				padding-top:rem(81px);
			}

			@include breakpoint(giant) {
				padding-top:rem(84px);
			}
		}
	}

	&.cmsBackend {
		#footer {
			.googleMaps {
				display:none;
			}
		}
	}
}

.cmsBackend  {

	.ewcms_form, .ewcms_iframe {
		top: 20% !important;
		z-index: 9999999 !important;
	}

	.contentSliderContainer {
		width: 33%;
	}

	.swiper-wrapper {
		display: block;
	}

	.swiper-container {
		overflow: visible;
	}

	.contentSliderContainer {
		margin-bottom: 14rem;
		.row {
			display: block;
		}
		.contentSlider {
			padding-bottom: 10rem;
		}
	}

	.swiper-button-next {
		display: none !important;
	}

	.swiper-button-prev {
		display: none !important;
	}

	input.ewcms_a {
		width: 420px !important;
	}

}

