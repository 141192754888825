.marginBottom {
  margin-bottom: rem($baseLineHeight);
}

.highlight {
  color: $secondary;
}

.btn {
	@extend %animatedTransform;
	background: $primary;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem($baseFontSize);
	line-height: rem($baseLineHeight);
	padding: rem($baseGap);
	text-align: center;
	text-decoration: none;
	font-family: $mainFont;
	box-shadow:rem(2px) rem(2px) 0 rgba(0,0,0,.25);


	&:hover,&:active,&:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}

	&.alt {
		background:$light;
		color:$dark;
		cursor:pointer; 

		&:hover,&:active,&:focus {
			background: $light;
			color: $dark;
		}
	}
}

small {
	&.margin {
		display:block;
		margin:rem($baseGap / 2) 0 rem($baseGap) 0;
	}
}

.bgOrange {
	background:$primary;
	color:$light;

	h1,  h2,  h3,  h4,  h5,  h6,  .h1,  .h2,  .h3,  .h4,  .h5,  .h6 {
		color:$light;
	}

	.btn {
		background:$light;
		color:$dark;
		&:hover,&:active,&:focus {
			background: $dark;
			color: $light;
		}
	}

	form.default {
		::-webkit-input-placeholder {
			color: rgba($light,.5);
		}
	
		::-moz-placeholder {
			color: rgba($light,.5);
		}
	
		:-ms-input-placeholder {
			color: rgba($light,.5) !important;
		}
	
		:-moz-placeholder {
			color: rgba($light,.5);
		}

		fieldset {
			margin: 0 0 0 0;
		}

		small {
			color:$light;
		}
	
		[type="text"],
		[type="tel"],
		[type="email"],
		[type="file"],
		input,
		textarea,
		select {
			background:transparent;
			border:rem(2px) solid $light;
			color:$light;

			&:focus {
				background: rgba(0,0,0,.25);
				outline: none;
			}
		}

		a {
			color:$light;
			text-decoration:underline;

			&:hover {
				color:$light;
				text-decoration:none;
			}
		}

		div {
			&.checkbox {
				padding:rem($baseGap) 0;
			}
		}

		@include breakpoint(giant) {
			[type="text"],
			[type="tel"],
			[type="email"],
			[type="file"],
			input,
			textarea,
			select {
				margin-bottom:rem($baseGap * 2);
			}

			textarea {
				&[name="Nachricht"] {
					height:rem(262px);
				}
      }
      
      div {
        &.checkbox {
          padding:0 0;
        }
      }
		}
	}
}

// .hugeCenter {
// 	@include breakpoint(huge) {
// 		align-items:center;
// 	}
// }

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none !important;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

.notificationArea {
  position: fixed;
  bottom: rem(40px);
  left: rem(40px);
  z-index: 99999999;
  visibility: hidden;
  display: block;
  
  @include breakpoint(large) {
    left: rem(60px);
  }

  > :not(:first-child):not(.hide).transitionIn {
    margin-top: $baseGap;
  }

  .notification {
    display: block;
    position: relative;
    transition: 300ms all ease-in-out;
    overflow: hidden;
    padding:0;
    background: $alert;
    font-size: rem(12px);
    line-height: rem(16px);
	  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    width: rem(300px);
    max-width: calc(100vw - 80px);
    transform-origin: left center;
    visibility: hidden;
    opacity: 0;
    max-height:none;
    height: 0;
    box-sizing:border-box;
    margin-left:-100px;

    .noteContent {
      padding:1rem;
      display: block;
    }

    @include breakpoint(large) {
      font-size:rem(16px);
      line-height: rem(22px);
    }

    &.transitionIn {
      visibility: visible;
      opacity: 1;
      max-height:9999999px;
      height: auto;
      margin-left:0;
    }

    &,
    a {
      color: $light;
    }

    &.success {
      background: #333;
      
      &, .close {
        color: #fff;
      }
    }
    &.error {
      background: $alert
    }
    &.success, &.error {
      p {
        margin-bottom: rem(5px);
      }
      p + ul {
        margin-top: rem(5px);
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      padding-right: 8px;
      cursor: pointer;
      color: #fff;
      font-size: 1.2rem;
    }

    &.hide {
      margin-left:-100px;
      opacity: 0;
      visibility: hidden;
      height:0;
    }
  }
}

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}